<template>
  <div class="mb-4 mt-2 item1">
      <label><i>{{tr('_new_claim_list')}}</i></label>
  </div>
</template>

<script>
export default {
    name: 'new-claim-list-report',
}
</script>

<style scoped>
.item1 {
    //background: #F1F1F1;
}
</style>
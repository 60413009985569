const modules = vueTools.importModule('modules', 'tools', 'custom').default;

modules.reportsMenu.endpoints.push({endpoint: 'report', label: 'Receipts List', componentName: 'ReceiptListReport', report: 'receiptlist'});

if (modules.financesMenu) {
    let i = _.findIndex(modules.financesMenu.endpoints, (r) => {
        return r.report == 'receiptlist';
    });
    if (i > -1) modules.financesMenu.endpoints.splice(i, 1);
}

export default modules;

<script>
const salesByUserReport = importVueComp('components/reports', 'SalesByUserReport', 'custom');
export default {
    name: 'custom-sales-by-user-report',
    mixins: [salesByUserReport],
    methods: {
        getData (self) {
            let r = salesByUserReport.methods.getData(this);
            r.headerColumns.splice(r.headerColumns.indexOf('SalesOrigin') + 1, 0, 'TripMode');
            r.fields.push({
                name: 'TripModeId', 
                relation: 'tripmode', 
                optionLabels: 'Name', 
                label: 'Trip Mode', 
                addBlank: true, 
                editor: 'select', 
            });
            r.headers.TripMode = "Trip Mode";
            r.fields = Object.assign([], r.fields)
            return r;
        },

    }
}
</script>


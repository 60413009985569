<template>
    <div>
        <report-window
            v-if="ready"
            endpoint="/arg/reports/vatpurchase"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :current.sync="current"
            :columnsClasses="columnsClasses"
            :notFilterable="options && options.notFilterable"
            :totalsOn="totalsOn"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
export default {
    name: "vat-purchase-report",
    props: ["options"],
    components: {
        ReportWindow,
    },
    computed: {
        columnsClasses () {
            return {
                Cost: 'text-right',
                Type: 'text-right',
                ['21']: 'text-right',
                Tax21: 'text-right',
                ['10.5']: 'text-right',
                ['Tax10.5']: 'text-right',
                ['27']: 'text-right',
                Tax27: 'text-right',
                ret_iva: 'text-right',
                per_iibb: 'text-right',
                per_iva: 'text-right',
                ['0']: 'text-right',
                tur_iva: 'text-right',
                Total: 'text-right'
            }
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
                {name: 'FilterDate', editor: 'select', label: 'Filtrar por', defValue: '0',
                    options: [
                        {value: '0', label: 'Fecha de Factura'},
                        {value: '1', label: 'Fecha Fiscal'},
                    ]
                },      
                {name: 'FromDate', editor: 'date', label: 'Desde'},
                {name: 'ToDate', editor: 'date', label: 'Hasta'},
            ],
            title: "Libro IVA Compras",
            headers: {
                InvoiceDate: 'Fecha',
                Type: 'Tipo',
                InvType: 'Tipo de comprobante',
                TaxCondition: 'Condición IVA',
                TaxNumber: 'CUIT',
                InvoiceNumber: 'Número de factura', 
                'Base21': 'Neto Gravado',
                'Base10.5': 'Neto Gravado',
                'Base27': 'Neto Gravado',
                'Base5': 'Neto Gravado',
                'Base2.5': 'Neto Gravado',
                'Base0': 'No Gravado',
                'Tax21': 'IVA 21%',
                'Tax10.5': 'IVA 10.5%',
                'Tax27': 'IVA 27%',
                'Tax5': 'IVA 5%',
                'Tax2.5': 'IVA 2.5%',
            },
            totalsOn: [
                '21',
                'Tax21',
                '10.5',
                'Tax10.5',
                '27',
                'Tax27',
                'ret_iva',
                'ret_iibb',
                'per_iibb',
                'per_iva',
                '0',
                'tur_iva',
                'Total'
            ],
            templates: [
                {name: 'InvoiceDate', callback: (row) => {
                        return moment(row.InvoiceDate).format("DD/MM/YYYY")
                    }
                },
                {name: 'FiscalDate', callback: (row) => {
                        return moment(row.FiscalDate).format("DD/MM/YYYY")
                    }
                },
                {name: 'Type', callback: (row) => {
                        if (row.Type) return {INVOICE: 'Factura', CREDIT: 'Nota de Crédito'}[row.Type];
                        return '';
                    }
                },
                {
                    name: 'TaxCondition',
                    callback: (row) => {
                        if (row.TaxCondition) return {RI: 'RI', M: 'M', E: 'E'}[row.TaxCondition];
                        return '';
                    }
                },
                {name: '21', callback: (row) => {                        
                        return tools.toNumber(row['21'], 2);
                    }
                },                
                {name: 'Tax21', callback: (row) => {                        
                        return tools.toNumber(row.Tax21, 2);
                    }
                },                
                {name: '10.5', callback: (row) => {                        
                        return tools.toNumber(row['10.5'], 2);
                    }
                },
                {name: 'Tax10.5', callback: (row) => {                        
                        return tools.toNumber(row['Tax10.5'], 2);
                    }
                },
                {name: '27', callback: (row) => {                        
                        return tools.toNumber(row['27'], 2);
                    }
                },
                {name: 'Tax27', callback: (row) => {                        
                        return tools.toNumber(row.Tax27, 2);
                    }
                },
                {name: 'ret_iva', callback: (row) => {                        
                        return tools.toNumber(row.ret_iva, 2);
                    }
                },
                {name: 'per_iibb', callback: (row) => {                        
                        return tools.toNumber(row.per_iibb, 2);
                    }
                },
                {name: 'per_iva', callback: (row) => {                        
                        return tools.toNumber(row.per_iva, 2);

                    }
                },
                {name: '0', callback: (row) => {                        
                        return tools.toNumber(row['0'], 2);                        
                    }
                },
                {name: 'tur_iva', callback: (row) => {                        
                        return tools.toNumber(row.tur_iva, 2);
                    }
                },
                {name: 'Total', callback: (row) => {                        
                        return tools.toNumber(row.Total, 2);
                    }
                }
            ],
        }
    },
    methods: {
    }
};
</script>

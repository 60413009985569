<template>
      <report-window endpoint="/reports/room_nights"
          v-if="ready"
          :fields="fields"
          :title="cardTitle || title"
          :headers="headers"
          :templates="templates"
          :perPage="(options && options.perPage)? options.perPage: 500"
          :hideFilters="options && options.hideFilters"
          :runMounted="options && options.runMounted"
          :cardView="options && options.cardView"
          :notFilterable="options && options.notFilterable"
          :headerColumns="options? options.headerColumns: null"
          :current.sync="current"
          :slotsFields="[]"
          :totalsOn="totalsOn"
          :totalLabel="totalLabel"
          :allowChart="true"
          :beforeRender="beforeRender"
          :chartOptions="chartOptions"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'room-nights',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            ready: false,
            fields: [
              {name: 'FromDate', editor: 'date', label: 'From', required: true},
              {name: 'ToDate', editor: 'date', label: 'To', required: true},
              {name: 'CityId', label: 'City', editor: 'select', 
                  optionLabels: 'Name', relation: 'city', addBlank: true,
              },
              { name: 'GroupBy', label: 'Group by', editor: 'select', required: true, defValue: 1,
                  options: [
                      {value: '1', label: 'Hotel'},
                      {value: '2', label: 'City'},
                      {value: '3', label: 'Country'},
                      {value: '4', label: 'Hotel Category'},
                      {value: '5', label: 'Agency'},
                  ]
              },

            ],
            title: 'Room Nights',
            headers: {
            },
            templates: [
            ],
            totalsOn: [],
            totalLabel: ['Hotel', 'City', 'Country', 'HotelCategory', 'Agency'],
            chartOptions: {
              y: 'Hotel',
              x: []
            }
        }
    },
    methods: {
        beforeRender (report) {
            for (let column of report.serverColumnsHeader) {
                if (column != 'Hotel' && column != 'City' && column != 'Country' && column != 'HotelCategory' && column != 'Agency') {
                    this.totalsOn.push(column)
                    this.chartOptions.x.push(column)
                }
            }
            this.totalsOn = Object.assign([], this.totalsOn);
        },
    }

}
</script>



<script>
const operPaymentListReport = importVueComp('components/reports', 'OperPaymentListReport', 'custom');
export default {
    name: 'custom-oper-payment-list-report',
    mixins: [operPaymentListReport],
    data () {
        return {
            headerColumns: ['id', 'DueDate', 'SupplierName', 'DateIn', 'Amount', 'CurrencyId', 'BookingId', 'ResNumber'
                , 'PaxName','PaymentDate', 'PaymentStatus', 'PaymentTypeId', 'PaymentAmount', 'PaymentCurrencyId', 'Comment'],

        }
    },
}
</script>


<script>
var EventBus = require('@/tools/event-bus').default;
const bookingReceipts = importVueComp('components/booking', 'BookingReceipts', 'custom');
var html = importRawTemplate(bookingReceipts);
let div = `
<div class="form-row">
    <button type="button" class="btn btn-primary col-2" @click="createInvoice()"
        rel="tooltip" data-placement="top" title="Email" :disabled="!canEdit" v-if="canInvoice">
        Facturar
    </button>
</div>
`;

var t1 = insertLast(html, div);

import Vue from 'vue';
let comp = Vue.component('custom-booking-receipts', {
    name: 'custom-booking-receipts',
    mixins: [bookingReceipts],
    template: t1,
    render: null,
    computed: {
        canInvoice () {
            return true;
        },
    },
    methods: {
        /*async addNew () {
            let model = await api.importMixinModule('bookingreceipt', 'model');
            let newReceipt = await new model({tableName: 'bookingreceipt'});
            newReceipt.BookingId = this.recordStore.id;
            if (this.recordStore.CompanyId) newReceipt.CompanyId = this.recordStore.CompanyId;
            EventBus.$emit('open-modal-record', {modalRecord: newReceipt, modalTable: 'bookingreceipt',
                modalId: 'new', closeAction: 'close-receipt'});
        },*/
        async createInvoice () {
            let model = await api.importMixinModule('invoice', 'model');
            let invoice = await api.get('/api/invoice/', {filters: JSON.stringify({BookingId: parseInt(this.recordStore.id)})})
            let record;
            let id = 'new';
            if (invoice && invoice[0]) {
                record = invoice[0];
                id = record.id;
            } else {
                record = await new model({tableName: 'invoice'});
                record.BookingId = this.recordStore.id;
                record.InvoiceDateFrom = this.recordStore.StartDate;
                record.InvoiceDateTo = this.recordStore.EndDate;
            }
            EventBus.$emit('open-modal-record', {modalRecord: record, modalTable: 'invoice', modalId: id});
        },
    },
})
export default comp;
</script>


<script>

const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'custom');
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'custom-booking-cover-main',
    mixins: [bookingCoverMain],
    methods: {
        setStatus () {
            let tabs = {};
            let hasPayments = api.payments && api.payments.length > 0;
            if (this.recordStore.Status == 'CONFIRMED') {
                tabs['receipts'] = true;
                tabs['operations'] = hasPayments;
            } else {
                tabs['receipts'] = false;
                tabs['operations'] = false;
            }
            this.$parent.$parent.$parent.$refs.navItems.updateItems(tabs);
        }
    }
}

</script>

<template>
    <div id="b-invoice" v-if="ready" @contextmenu="handleClick($event)">
        <div class="button-fixed">
            <button type="button" class="btn btn-primary action-button"
                @click="print()">
                <font-awesome-icon icon="print"/>
            </button>
        </div>
        <div class="container container-body">
            <div class="row mb-5">
                <div class="col-6 row">
                    <div class="col-12">
                        <img width="200" id="logo" src="@/extra/custom/img/logo-full.png" class="">
                    </div>
                </div>
                <div class="col-6 row align-items-center">
                    <div class="col-12">
                        <h2>
                            {{getDocName}}
                        </h2>
                    </div>
                </div>
                <div class="col-6 row">
                    <div class="col-12">
                        Signature DMC
                    </div>
                    <div class="col-12">
                        Gorriti 4886, Bs As, Argentina
                    </div>
                    <div class="col-12">
                        <a href="mailto:info@signaturedmc.com">
                            info@signaturedmc.com
                        </a>
                    </div>
                    <div class="col-12">
                        Tel. +54 911-3455-0799
                    </div>
                </div>
                <div class="col-6 row">
                    <div class="col-12">
                        {{getDocName}} Number: {{getNumber}}
                    </div>
                    <div class="col-12">
                        Date: {{getDate(new Date())}}
                    </div>
                    <div class="col-12">
                        Customer: {{booking.PaxName}}
                    </div>
                    <div class="col-12 row mt-3" v-if="booking.AgencyId">
                        <div class="col-12">
                            <strong>Company:</strong> {{booking.Agency.Name}}
                        </div>
                        <div class="col-12" v-for="row of agencyAddress">
                            {{row}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mr-5">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12" v-if="booking.SalesUser">
                            Seller: {{booking.SalesUser.FirstName}} {{booking.SalesUser.LastName}}
                        </div>
                    </div>
                    <hr class="my-2 hr-strong">
                    <div class="row mt-3" v-for="row of recordStore.paxQuantityPrices">
                        <div class="col-2">
                            {{row.pax}}
                        </div>
                        <div class="col-8">
                            {{booking.Title}}
                        </div>
                        <div class="col-2 text-right">
                            {{booking.PriceCurrencyId}} {{row.price.toFixed(0)}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                        </div>
                        <text-rows-style class="col-8" :text="booking.Include"></text-rows-style>
                        <div class="col-2 text-right">
                        </div>
                    </div>
                    <hr class="my-2 hr-strong">
                    <div class="row">
                        <div class="col-10">
                            TOTAL TO BE PAID
                        </div>
                        <div class="col-2 text-right">
                            {{amount}}
                        </div>
                    </div>
                    <hr class="my-2 hr-strong">
                </div>
            </div>

        </div>
        <footer class="not-print">
            <div class="container">
                <div class="d-flex align-items-center">
                    <div class="col">
                        <span>Signature DMC, Gorriti 4886, Buenos Aires, Argentina</span><br>
                        <a href="mailto:info@signaturedmc.com">
                            info@signaturedmc.com
                        </a>
                    </div>
                    <div class="col text-right">
                        <img width="170" src="@/extra/custom/img/logo-full.png">
                    </div>
                </div>
            </div>
        </footer>

        <div class="divFooter">
            <div class="container">
                <div class="d-flex align-items-center">
                    <div class="col">
                        <span>Signature DMC, Gorriti 4886, Buenos Aires, Argentina</span><br>
                        <a href="mailto:info@signaturedmc.com">
                            info@signaturedmc.com
                        </a>
                    </div>
                    <div class="col text-right">
                        <img width="170" src="@/extra/custom/img/logo-full.png">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import '@/extra/custom/css/invoice.scss';
import { mapState } from 'vuex';
import { WidthType, BorderStyle, Document, Paragraph, Packer, TextRun, Media, Table, TableRow, TableCell, AlignmentType,
  TableLayoutType, HorizontalPositionAlign, ShadingType, UnderlineType, HorizontalPositionRelativeFrom, VerticalAlign,
  VerticalPositionRelativeFrom, Footer, Hyperlink} from "docx";
import { saveAs } from 'file-saver';
var EventBus = require('@/tools/event-bus').default;
//var moment = require('moment');

export default {
    name: 'booking-invoice',
    props: ['id', 'hash', 'payment'],
    data: function () {
        return {
            invoiceURL: null,
            resForm: null,
            booking: null,
            ready: false,
        }
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        agencyAddress () {
            let res = [];
            if (this.booking.Agency && this.booking.Agency.Address) {
                return this.booking.Agency.Address.split(',');
            }
            return res;
        },
        amount () {
            if (!this.payment) {
                return this.booking.PriceCurrencyId + ' ' + this.getTotalInCurrency(this.recordStore.finalTotalWithFlights).toFixed(0);
            }
            let row = _.find(this.receipts, (r) => r.id == this.payment);
            if (row) {
                return row.CurrencyId + ' ' + row.Amount.toFixed(0);
            }
        },
        getNumber () {
            if (!this.payment) {
                return this.booking.id + '-000001' ;
            }
            let width = 6;
            width -= this.payment.length;
            if ( width > 0 ){
                return this.booking.id + "-" + new Array( width + (/\./.test( this.payment ) ? 2 : 1) ).join( '0' ) + this.payment;
            }
            return this.booking.id + "-" + this.payment + "";
        },
        getDocName () {
            if (!this.payment) return 'Invoice';
            let row = _.find(this.receipts, (r) => r.id == this.payment);
            if (row && row.Amount && row.Amount<0) {
                return 'Credit Note';
            }
            return 'Invoice';
        }

    },
    async mounted (){
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r = await api.get('/public/api/get_booking/' + this.hash + '/' + this.id);
        if (r) {
            r._public = true;
            let record = await new model({record: r, notFieldOptions: true});
            this.$store.commit('initRecord', {record});
            this.booking = tools.plainObject(this.recordStore);
            if (this.payment) {
                let res = await api.get('/api/bookingreceipt/bybookingid/' + this.recordStore.id);
                if (res) this.receipts = res;
            }
            api.setProcessing(false)
            this.ready = true;
        }
    },
    methods: {
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        getDate  (d) {
            let r = moment(d).local().locale(this.booking.Language.Code).format('MMM DD, YYYY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        print: function() {
            print()
        },
        handleClick (event) {
            this.$root.$children[0].handleClick(event);
        }

    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },
}
</script>

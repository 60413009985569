<template>
    <div id="fstyles">
        <div v-if="recordStore && !processing">
            <button type="button" class="btn btn-primary action-button button-fixed" @click="printPDF">
                <font-awesome-icon icon="print"/>
            </button>


            <div class="container">
                <div class="row">
                    <div class="col-12">
                            <img src="@/extra/custom/img/logo-itinerary.png" alt="aotg logo" style="height: 100px">
                    </div>
                    <div class="col-12 mt-4">
                        <h1>{{tr('Itinerary')}}</h1>
                    </div>
                    <div class="col-12">
                        <div class=""></div>
                    </div>
                </div>
            </div>

            <booking-final-preview
            ></booking-final-preview>
            <br>
            <div class="container">
                <div class="col-12 info-box">
                    <h6>{{tr('Terms & Conditions')}}</h6>
                    <div class="info-box-body">
                        <text-rows-style tag="p" :text="getTermsAndConditions"></text-rows-style>
                    </div>
                </div>
            </div>

            <itinerary-footer></itinerary-footer>
        </div>
    </div>
</template>

<script>
let bookingFinalView = importVueComp('components/documentation', 'BookingFinalView', 'custom');
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
import '@/extra/custom/css/fstyles.scss';
export default {
    name: 'booking-final-view',
    mixins: [bookingFinalView],
    components: {
        'itinerary-footer': itineraryFooter,
    },
    computed: {
        getTermsAndConditions () {
            if (!this.recordStore) return '';
            let r = _.find(api.tables.termsandconditions, (c) => c.LanguageId == this.recordStore.LanguageId);
            if (r) return r.Terms;
            return '';
        },
    }
}
</script>


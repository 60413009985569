import { WidthType, BorderStyle, Document, Paragraph, Packer, TextRun, Media, Table, TableRow, TableCell, AlignmentType,
  TableLayoutType, HorizontalPositionAlign, ShadingType, UnderlineType, HorizontalPositionRelativeFrom, VerticalAlign,
  VerticalPositionRelativeFrom} from "docx";
var EventBus = require('@/tools/event-bus').default;

class Docx {

    constructor (record) {
        this.booking = record;
        this.$dayNr = null;
        this.$message = null;
    }

    tr (t) {
        return tr(t, this.booking.Language.Code);
    }

    //// getters /////

    get message () {
        return this.$message;
    }

    get dayNr () {
        return this.$dayNr;
    }

    get brandColor () {
        return '224061';
    }

    get textColor () {
        return '224061';
    }

    get docStyles () {
        return [
            {
                id: "Heading1",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 14, bold: false, color: "FFFFFF" },
                paragraph: { alignment: AlignmentType.CENTER, spacing: { line: 280 } },
            },
            {
                id: "Heading2",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 14, bold: true, color: "FFFFFF" },
                paragraph: { alignment: AlignmentType.CENTER, spacing: { line: 280 } },
            },
            {
                id: "Heading3",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 28, bold: true, color: this.textColor, italics: true },
                paragraph: { alignment: AlignmentType.LEFT, spacing: { line: 280 } },
            },
            {
                id: "Prices",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 14, bold: true, color: this.textColor },
                paragraph: { alignment: AlignmentType.CENTER, spacing: { line: 280 } },
            },
            {
                id: "Summary",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, color: this.textColor, italics: true },
                paragraph: { alignment: AlignmentType.JUSTIFIED, spacing: { line: 280 } },
            },
            {
                id: "Spacer",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 10},
                paragraph: { alignment: AlignmentType.LEFT, spacing: { line: 280 } },
            },
            {
                id: "DayHeading1",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, bold: false, color: this.textColor },
                paragraph: { alignment: AlignmentType.CENTER, spacing: { line: 280 } },
            },
            {
                id: "DayHeading2",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, bold: true, color: this.textColor },
                paragraph: { alignment: AlignmentType.CENTER, spacing: { line: 280 } },
            },
            {
                id: "DayHeading3",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, bold: true, color: this.textColor },
                paragraph: { alignment: AlignmentType.LEFT, spacing: { line: 280 } },
                indent: { left: 720 },
            },
            {
                id: "DayHeading4",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, bold: false, color: this.textColor },
                paragraph: { alignment: AlignmentType.LEFT, spacing: { line: 280 } },
            },
            {
                id: "serviceText",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, color: this.textColor },
                paragraph: { alignment: AlignmentType.JUSTIFIED, spacing: { line: 280 } },
            },
            {
                id: "serviceTitle",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, color: this.textColor, bold: true, italics: true },
                paragraph: { alignment: AlignmentType.JUSTIFIED, spacing: { line: 280 } },
            },
            {
                id: "serviceTitleOptional",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 18, color: 'red', bold: true, italics: true },
                paragraph: { alignment: AlignmentType.JUSTIFIED, spacing: { line: 280 } },
            },
            {
                id: "ListParagraph",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: { font: "Helvetica", size: 14, bold: true, color: this.textColor },
                paragraph: { alignment: AlignmentType.LEFT, spacing: { line: 280 } },
            },

        ]
    }


    ///// methods /////

    async create () {
        console.log('getDocx 22')

        let doc = new Document({
            styles: {
                paragraphStyles: this.docStyles
            }
        });
        let image;
        if (this.booking.ImageId && this.booking.Image) {
            //foto de portada
            image = Media.addImage(doc, await this.getBase64Image(this.booking.Image.id, this.booking.Image.url, null, 0.5), 730, 316);
        }

        let cellShading1 = { fill: this.brandColor, val: ShadingType.PERCENT_100 };

        let spacer = new Paragraph({style: 'Spacer'})

        let section = {
            properties: {
                top: 500,
                left: 500,
                right: 500,
                bottom: 500
            },
            children: [],
        };
        if (image) {
            section.children.push(new Paragraph(image));
            section.children.push(spacer);
        }
        let tableHeader = this.getTableHeader(cellShading1);
        if (tableHeader) {
            section.children.push(tableHeader);
            section.children.push(spacer);
        }
        section.children.push(new Paragraph({ text: this.booking.Title, style: "Heading3"}));
        section.children.push(spacer)

        /*let stacks = this.getStacks([{ text: this.booking.Summary, html: true}]);
        for (let stack of stacks) {
            for (let text of stack.text) {
                section.children.push(new Paragraph({ text: text, style: "Summary"}))
            }
        }*/

        let itineraryHeader = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            shading: cellShading1,
                            children: [
                                new Paragraph({ text: this.tr('Itinerary'), style: "Heading2" }),
                            ],
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [ new Paragraph({ text: '' })],
                            borders: { bottom: { style: BorderStyle.SINGLE, size: 1, color: "FFFFFF"}},
                            width: {
                                size: 60,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            shading: cellShading1,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE,
                            },
                            children: [
                                new Paragraph({ text: this.booking.BookingDays.length + ' '
                                    + this.tr('Days') + ' / ' + (this.booking.BookingDays.length - 1)
                                    + ' ' + this.tr('Nights'), style: "Heading2" }),
                            ],
                        }),
                    ],
                }),
            ],
            alignment: AlignmentType.CENTER,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: [2200, 6600, 2200],
        });

        section.children.push(itineraryHeader)
        section.children.push(spacer)
        section.children.push(spacer)

        //await this.getDayImages();

        for (let day of this.booking.BookingDays) {
            let headerDay = this.getDayHeader(day);
            section.children.push(headerDay);
            let bodyDayTables = await this.getDayBody(day, doc);
            section.children.push(spacer);
            for (let t of bodyDayTables) {
                section.children.push(t);
            }
            section.children.push(spacer);
        }

        doc.addSection(section);

        if (this.booking.TemplateType == 'NONE') {
            let section2 = {
                properties: {
                    top: 500,
                    left: 500,
                    right: 500,
                    bottom: 500
                },
                children: [],
            };

            let priceTable = this.getPriceFlightsTable();
            if (priceTable) {
                section2.children.push(priceTable);
                section2.children.push(spacer);
            }

            let rowFlights = (this.booking.getFlightsListInternal.length>0) == (this.booking.getFlightsListInternational.length>0);
            if (rowFlights && this.booking.getFlightsListInternal.length>0) {
                let flightsTable = this.getFlightsTable();
                section2.children.push(flightsTable);
                section2.children.push(spacer);
            }

            let includesTable = this.getIncludeTables();
            section2.children.push(includesTable);
            section2.children.push(spacer);

            doc.addSection(section2);
        }

        return doc;

    }

    getTableHeader (cellShading1) {
        let tableHeader = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            shading: cellShading1,
                            children: [
                                new Paragraph({ text: this.tr('PROPOSE OF TRAVEL FOR'), style: "Heading1" }),
                                new Paragraph({ text: this.booking.PaxName, style: "Heading2" })
                            ],
                        }),
                        new TableCell({
                            shading: cellShading1,
                            children: [
                                new Paragraph({ text: this.tr('DURATION'), style: "Heading1" }),
                                new Paragraph({
                                    text: this.booking.BookingDays.length + ' ' + this.tr('Days'),
                                    style: "Heading2"
                                })
                            ],
                        }),
                        new TableCell({
                            shading: cellShading1,
                            children: [
                                new Paragraph({ text: this.tr('TRIP MODE'), style: "Heading1" }),
                                new Paragraph({ text: this.tr(this.booking.TripMode.Name), style: "Heading2" })
                            ],
                        }),
                        new TableCell({
                            shading: cellShading1,
                            children: [
                                new Paragraph({ text: this.tr('DIFFICULTY'), style: "Heading1" }),
                                new Paragraph({ text: this.tr(this.booking.Difficulty), style: "Heading2" })
                            ],
                        }),
                    ],
                }),
            ],
            alignment: AlignmentType.CENTER,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: [2750, 2750, 2750, 2750],
        });
        return tableHeader;
    }

    getFlightsTable () {
        let cells = [];
        let cellFlights1 = this.getMemoFieldsTable(this.booking.getFlightsListInternal, this.tr('Internal operational flights'));
        cells.push(cellFlights1);
        let cellFlights2 = this.getMemoFieldsTable(this.booking.getFlightsListInternational, this.tr('International flights'));
        cells.push(cellFlights2);

        let row = new TableRow({children: cells})

        let table = new Table({
            rows: [row],
            alignment: AlignmentType.CENTER,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            margins: {
                marginUnitType: WidthType.AUTO,
                left: 200,
                right: 200,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: [5500, 5500],
        });

        return table;

    }

    getIncludeTables () {
        let cells = [];
        let stacks1 = this.getStacks([{ text: this.booking.Include, html: true}]);
        let textList = [];
        for (let s of stacks1) {
            for (let p of s.text) {
                textList.push(p);
            }
        }
        let includes = this.getMemoFieldsTable(textList, this.tr('Prices include'));
        cells.push(includes);
        let stacks2 = this.getStacks([{ text: this.booking.NotInclude, html: true}]);
        textList = [];
        for (let s of stacks2) {
            for (let p of s.text) {
                textList.push(p);
            }
        }
        let notIncludes = this.getMemoFieldsTable(textList, this.tr("Prices DON'T include"));
        cells.push(notIncludes);

        let row = new TableRow({children: cells})

        let table = new Table({
            rows: [row],
            alignment: AlignmentType.CENTER,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            margins: {
                marginUnitType: WidthType.AUTO,
                left: 200,
                right: 200,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: [5500, 5500],
        });

        return table;

    }

    getPriceFlightsTable () {
        let cells = [];
        if (this.booking.anyQuoteSelected) {
            let cell = this.getPriceTableCell();
            if (cell) cells.push(cell);
        }

        let rowFlights = (this.booking.getFlightsListInternal.length>0) == (this.booking.getFlightsListInternational.length>0);
        let columnWidths = [5500];
        if (!rowFlights) {
            if (this.booking.getFlightsListInternal.length>0) {
                let cellFlights = this.getMemoFieldsTable(this.booking.getFlightsListInternal, this.tr('Internal operational flights'));
                cells.push(cellFlights);
                columnWidths.push(5500);
            }
        }
        if (cells.length == 0) return;

        let row = new TableRow({children: cells})

        let table = new Table({
            rows: [row],
            alignment: AlignmentType.CENTER,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            margins: {
                marginUnitType: WidthType.AUTO,
                left: 200,
                right: 200,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: columnWidths,
        });

        return table;

    }

    getMemoFieldsTable (stacks, text) {

        let cellShading = { fill: this.brandColor, val: ShadingType.PERCENT_100 };

        let headerCell = new TableCell({
            shading: cellShading,
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: {
                top: { style: BorderStyle.NONE, size: 0, color: "000000"},
                bottom: { style: BorderStyle.NONE, size: 0, color: "000000"},
                left: { style: BorderStyle.NONE, size: 0, color: "000000"},
                right: { style: BorderStyle.NONE, size: 0, color: "000000"},
            },
            children: [
                new Paragraph({ text: text, style: "Heading1" }),
            ],
        });

        let rows = [];
        let headerRow = new TableRow({children: [headerCell]});
        rows.push(headerRow);

        let paragraphs = [];
        for (let f of stacks) {
            let p = { text: f, style: "Prices" };
            if (f.text) p.text = f.text;
            if (f.bullet) {
                p.bullet = {level: 0};
            }
            paragraphs.push(new Paragraph(p));
        }

        let bodyCell = new TableCell({
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: {
                top: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
                bottom: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
                left: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
                right: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
            },
            children:  paragraphs ,
        });

        let bodyRow = new TableRow({children: [bodyCell]});
        rows.push(bodyRow);

        let table = new Table({
            rows: rows,
            alignment: AlignmentType.CENTER,
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: [5000],
        });


        let cell = new TableCell({
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            borders: {
                top: { style: BorderStyle.NONE, size: 0, color: "000000"},
                bottom: { style: BorderStyle.NONE, size: 0, color: "000000"},
                left: { style: BorderStyle.NONE, size: 0, color: "000000"},
                right: { style: BorderStyle.NONE, size: 0, color: "000000"},
            },
            children: [table],
        });
        return cell;
    }

    getRoomsNames (rooms) {
        let r = [];
        for (let roomId in rooms) {
            if (rooms[roomId].Checkin) continue;
            if (r.indexOf(rooms[roomId].Name)==-1) {
                r.push(rooms[roomId].Name);
            }
        }
        return r.join(' / ');
    }

    getRoomTypeNames (t) {
        let roomsNames = this.getRoomsNames(t.Room)
        let rooms = roomsNames.split(' / ');
        let res = [];
        for (let r of rooms) {
            res.push(this.tr(r))
        }
        if (this.booking.Language.Code!='en') return this.tr('Room') + ' ' + res.join(' / ');
        return res.join(' / ') + ' ' + this.tr('Room');
    }

    getTotalInCurrency (price) {
        return travelTools.currencyConvert(this.booking.CurrencyId, this.booking.PriceCurrencyId,
            price, this.booking.CurrencyRates);
    }


    getPriceTableCell () {
        let priceText = this.tr('Price per Person');
        if (this.booking.ShowPriceBy=='TOTAL_TRIP') {
            priceText = this.tr('Total Trip Price');
        }
        let cellShading = { fill: this.brandColor, val: ShadingType.PERCENT_100 };

        let headerCell = new TableCell({
            shading: cellShading,
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: 2,
            borders: {
                top: { style: BorderStyle.NONE, size: 0, color: "000000"},
                bottom: { style: BorderStyle.NONE, size: 0, color: "000000"},
                left: { style: BorderStyle.NONE, size: 0, color: "000000"},
                right: { style: BorderStyle.NONE, size: 0, color: "000000"},
            },
            children: [
                new Paragraph({ text: priceText, style: "Heading1" }),
            ],
        });

        let rows = [];
        let headerRow = new TableRow({children: [headerCell]});
        rows.push(headerRow);

        for (let hotelCategoryId in this.booking.hotelCategoriesNames) {
            let hotelCategory = this.booking.hotelCategories[hotelCategoryId];
            if (Object.keys(this.booking.hotelCategoriesNames).length>0 && Object.keys(this.booking.hotelCategoriesNames)[0]!='_no_hotel_category_') {
                let hotelText = '';
                if (this.booking.Language.Code!='en') hotelText += this.tr('Accommodation') + ' ';
                hotelText += hotelCategory;
                if (this.booking.Language.Code=='en') hotelText += ' ' + this.tr('Accommodation');
                let c = this.getPriceCell(hotelText, 2);
                let r = new TableRow({children: [c]});
                rows.push(r);
            }
            if (this.booking.ShowPriceBy=='TOTAL_TRIP') {
                let text = this.booking.PriceCurrencyId + ' ' + this.booking.finalTotal.toFixed(0);
                let c = this.getPriceCell(text, 2);
                let r = new TableRow({children: [c]});
                rows.push(r);
            } else {
                for (let t of this.booking.bookingTotals) {
                    if (!t.Quote.Price || !this.booking.isHotelCategory(t, hotelCategoryId)) continue;
                    let text = '';
                    if (t.Room && this.booking.isHotelCategory(t, hotelCategoryId)) {
                        text += `${this.tr(t.pType)} - ${this.getRoomTypeNames(t)} (${this.tr('based on')} ${t.pax} ${this.tr('pax')})`;
                    } else if (this.booking.isHotelCategory(t, hotelCategoryId)) {
                        text += `${this.tr(t.pType)} (${this.tr('based on')} ${t.pax} ${this.tr('pax')})`;
                    }
                    if (t.Quote.Comment) text += ' ' + t.Quote.Comment;
                    let c = this.getPriceCell(text);
                    if (this.booking.QuoteBy=='QUANTITY' && t.Quote.Price && this.booking.isHotelCategory(t, hotelCategoryId)) {
                        text = this.booking.PriceCurrencyId + ' ' + this.getTotalInCurrency(t.Quote.Price/t.base).toFixed(0);
                    } else if (t.Quote.Price && this.booking.isHotelCategory(t, hotelCategoryId)) {
                        text = this.booking.PriceCurrencyId + ' ' + this.getTotalInCurrency(t.Quote.Price).toFixed(0);
                    }
                    let p = this.getPriceCell(text);
                    let r = new TableRow({children: [c, p]});
                    rows.push(r);
                }
            }
            if (this.booking.BookingFlights.length>0 && this.booking.finalTotalFlightsNotIncluded && this.booking.ShowPriceBy=='TOTAL_TRIP') {
                let c = this.getPriceCell(this.tr('Internal Flights'));
                let text = this.booking.PriceCurrencyId + ' ' + this.booking.finalTotalFlightsNotIncluded.Price.toFixed(0);
                let p = this.getPriceCell(text);
                let r = new TableRow({children: [c, p]});
                rows.push(r);
            }
            if (this.booking.ShowPriceBy!='TOTAL_TRIP') {
                for (let key in this.booking.flightsNotIncluded) {
                    let flight = this.booking.flightsNotIncluded[key];
                    let fText;
                    if (flight.FlightType=='LOCAL') {
                        fText = this.tr('Internal Flights');
                    } else if (flight.FlightType=='INTERNATIONAL') {
                        fText = this.tr('International Flights');
                    }
                    fText += ' ' + this.tr(flight.pType);
                    if (flight.Comment) {
                        fText += ' ' + flight.Comment;
                    }
                    let c = this.getPriceCell(fText);
                    let text = this.booking.PriceCurrencyId + ' ' + this.getTotalInCurrency(flight.Price).toFixed(0);
                    let p = this.getPriceCell(text);
                    let r = new TableRow({children: [c, p]});
                    rows.push(r);
                }
            }
        }

        let table = new Table({
            rows: rows,
            alignment: AlignmentType.CENTER,
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: [2500, 2500],
        });


        let cell = new TableCell({
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            borders: {
                top: { style: BorderStyle.NONE, size: 0, color: "000000"},
                bottom: { style: BorderStyle.NONE, size: 0, color: "000000"},
                left: { style: BorderStyle.NONE, size: 0, color: "000000"},
                right: { style: BorderStyle.NONE, size: 0, color: "000000"},
            },
            children: [table],
        });
        return cell;
    }

    getPriceCell (text, cp) {
        let columnSpan = 1;
        if (cp) columnSpan = cp;
        let c = new TableCell({
            margins: {
                marginUnitType: WidthType.AUTO,
                top: 100,
                bottom: 100,
                left: 200,
                right: 200,
            },
            verticalAlign: VerticalAlign.CENTER,
            columnSpan: columnSpan,
            borders: {
                top: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
                bottom: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
                left: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
                right: { style: BorderStyle.SINGLE, size: 3, color: this.brandColor},
            },
            children: [
                new Paragraph({ text: text, style: "Prices" }),
            ],
        });
        return c;
    }

    getDayName (day) {
        let dayNrTitle;
        let dayNrName;
        if (this.booking.ShowDates) {
            if (!day.GroupDays) {
                dayNrTitle = this.tr('Day');
            } else {
                dayNrTitle = this.tr('Days');
            }
            dayNrName = this.getDatesText(day);
        } else {
            dayNrTitle = this.tr('Day');
            dayNrName = this.getDayNumber(day);
        }
        return `${dayNrTitle} ${dayNrName}`;
    }

    getDayTableRow (day) {
        let dayName = this.getDayName(day);
        let dayShading = { fill: "F1F1F2", val: ShadingType.PERCENT_100 };
        let row = new TableRow({
            children: [
                new TableCell({
                    shading: dayShading,
                    margins: {
                    },
                    width: {
                        size: 15,
                        type: WidthType.PERCENTAGE,
                    },
                    children: [
                        new Paragraph({ text: dayName, style: "DayHeading1" }),
                    ],
                    borders: {
                        top: { style: BorderStyle.SINGLE, size: 5, color: "000000"},
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                        left: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                        right: { style: BorderStyle.SINGLE, size: 3, color: "000000"},
                    }
                }),
                new TableCell({
                    margins: {
                        left: 200,
                    },
                    width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                    },
                    shading: dayShading,
                    children: [
                        new Paragraph({ text: this.getCitiesNames(day), style: "DayHeading3" }),
                        new Paragraph({ text: day.Title, style: "DayHeading4" }),
                    ],
                    borders: {
                        top: { style: BorderStyle.SINGLE, size: 5, color: "000000"},
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                        left: { style: BorderStyle.SINGLE, size: 3, color: "000000"},
                        right: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                    }
                }),
                new TableCell({
                    width: {
                        size: 35,
                        type: WidthType.PERCENTAGE,
                    },
                    shading: dayShading,
                    children: [],
                    borders: {
                        top: { style: BorderStyle.SINGLE, size: 5, color: "000000"},
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                        left: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                        right: { style: BorderStyle.SINGLE, size: 0, color: "F1F1F2"},
                    }
                }),
            ],
        });
        return row;
    }

    getDayHeader (day) {
        let headerTable = this.getDayTableRow(day);
        let dayHeader = new Table({
            rows: [headerTable],
            alignment: AlignmentType.CENTER,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            margins: {
                marginUnitType: WidthType.AUTO,
                left: 200,
            },
            layout: TableLayoutType.FIXED,
            columnWidths: _.map(headerTable.options.children, function(r) {
                return r.options.width.size * 11000 / 100;
            }),
        });
        return dayHeader;
    }

    getTextObject (p, r) {
        let res = {
            text: p.text,
            bold: p.bold,
            italics: p.italics,
            bullet: p.bullet,
        }
        if (!res.bold && r && r.bold) res.bold = r.bold;
        if (!res.italics && r && r.italics) res.italics = r.italics;
        if (p.style) {
            let s = _.find(this.docStyles, (r) => r.id == p.style);
            if (s) {
                if (s.run.bold) res.bold = s.run.bold;
                if (s.run.italics) res.italics = s.run.italics;
                if (s.run.font) res.font = s.run.font;
                if (s.run.size) res.size = s.run.size;
            }
        }
        if (p.color) res.color = p.color.replace('#', '');
        if (p.justify) res.alignment = VerticalAlign.JUSTIFIED;
        if (p.decoration=='underline') {
            res.underline = {
                type: UnderlineType.SINGLE,
                color: res.color? res.color: this.textColor,
            }
        }
        return res;
    }

    getServiceParagraphs (t, img) {
        let objects = [];
        let bullets = false;
        let justify = false;
        for (let p of t.text) {
            if (p.bullet) bullets = true;
            if (p.justify) justify = true;
            objects.push(new TextRun(this.getTextObject(p)));
        }
        let r = { children: objects, style: t.style };
        if (t.bullet) r.bullet = t.bullet;
        if (bullets && justify) {
            r.alignment = VerticalAlign.JUSTIFIED;
            //console.log(r.alignment)
        }
        return {p: new Paragraph(r), image: img};
    }

    getServiceParagraphsList (t) {
        let res = {image: t.image, list: [], border: t.border};
        for (let i in t.paragraphList){
            let bullets = false;
            let r = t.paragraphList[i];
            let objects = [];
            for (let p of r.text) {
                if (p.bullet) bullets = true;
                let to = this.getTextObject(p, r)
                objects.push(new TextRun(to));
            }
            let style = t.style;
            if (r.style) style = r.style;
            if (!style && r.tStyle) style = r.tStyle;
            if (!style && t.tStyle) style = t.tStyle;
            let p  = { children: objects, style: style };
            if (t.bullet || bullets) p.bullet = {level: 0};
            res.list.push(new Paragraph(p));
        }
        return res;
    }

    getServiceLength (t) {
        let c = 0;
        if (!t.paragraphList) {
            for (let p of t.text) {
                c += p.text.length;
            }
        } else {
            for (let r of t.paragraphList) {
                for (let p of r.text) {
                    c += p.text.length;
                }
            }
        }
        return c;
    }

    async getDayBody (day, doc) {
        console.log('DayNr', day.DayNr)
        this.$message = `${tr('processing')} ${tr('day')} ${day.DayNr + 1}`;
        this.$dayNr = day.DayNr;
        let spacer = {
            rowList: [{text: [{text: ''}]}],
            style: 'serviceText',
            spacer: true
        }
        let tables = [];
        let objects = this.getServiceObjects(day);
        let footerData = {rowList: [], style: 'serviceText'}
        /*if (this.booking.typeOfServices[day.DayNr]) {
            footerData.rowList.push({text: [{text: this.booking.typeOfServices[day.DayNr], italics: true, bold: true }]})
        }*/
        if (this.getMeals(day.DayNr)) {
            footerData.rowList.push({
                text: [
                    {text: this.tr('Meals Included') + ': ', italics: true, bold: true },
                    {text: this.getMeals(day.DayNr), italics: true }
                ]
            })
        }
        if (day.EntranceIncluded) {
            footerData.rowList.push({text: [{text: this.tr('_entrance_includes')}]})
        }
        if (day.PaxHotel==1) {
            footerData.rowList.push({
                text: [
                    {text: this.tr('Accommodation') + ': ', style: 'serviceTitle' },
                    {text: this.tr('Booked by yourself'), italics: true }
                ]
            })
        }
        objects.push(footerData);


        let hotelImg;
        let hotelRow;

        if (this.booking.hotelListByDate[day.DayNr] &&
          this.booking.hotelListByDate[day.DayNr].BookingDayRooms &&
          this.booking.hotelListByDate[day.DayNr].BookingDayRooms[0] &&
          this.booking.hotelListByDate[day.DayNr].BookingDayRooms[0].Hotel) {
          hotelRow = this.booking.hotelListByDate[day.DayNr].BookingDayRooms[0].Hotel;
        }

        if (hotelRow &&
            hotelRow.HotelPhotos[0] &&
            hotelRow.HotelPhotos[0].Image) {
            hotelImg = hotelRow.HotelPhotos[0].Image;
        }

        let hotelData = {rowList: [], notSpacer: true, style: 'serviceText'}

        if (this.booking.hotelsByDay[day.DayNr] && this.ifHotel(day.BookingDayHotels[0])) {
            let hotelTitle = {
                text: [
                    {text:  this.tr('Accommodation'), style: 'serviceTitle' },
                ],
                image: null
            }
            if (hotelImg && hotelImg.id) {
                hotelTitle.image = {img: hotelImg};
            }
            hotelData.rowList.push(hotelTitle);
            if (hotelRow && hotelRow.Name) {

                if (hotelRow.HotelCategory) {
                    hotelData.rowList.push({
                        text: [
                            //{text: hotelRow.HotelCategory.Name + ': ', italics: true, bold: true },
                            //{text: hotelRow.Name, italics: true, bold: true },
                            {text: this.getRoomCategoryName(this.booking.hotelsByDay[day.DayNr]), italics: true, bold: true },
                        ]
                    })
                } else {
                    hotelData.rowList.push({
                        text: [
                            {text: hotelRow.Name, italics: true, bold: true },
                            {text: '(' + hotelRow.rooms + ' ' + hotelRow.types + ')', italics: true, bold: true },
                        ]
                    })
                }
                if (hotelRow.WebSite) {
                    hotelData.rowList.push({
                        text: [
                            {
                                text: hotelRow.WebSite
                                /*text: doc.createHyperlink(
                                    hotelRow.WebSite,
                                    hotelRow.WebSite
                                )*/
                            }
                        ]
                    })
                }

                let hotelObjects = this.getHotelObjects(this.getHotelText(hotelRow.HotelDescriptions, this.booking.LanguageId));

                for (let n in hotelObjects) {
                    let o = hotelObjects[n];
                    let p = { text: [] };
                    for (let t of o.text) {
                        p.text.push({text: t.text, style: 'serviceText'});
                    }
                    hotelData.rowList.push(p);
                }
            }
        } else if (this.booking.hotelsByDay[day.DayNr]){
            hotelData.rowList.push({
                text: [
                    {text:  this.tr('Accommodation') + ': ', style: 'serviceTitle' },
                    {text: this.getRoomCategoryName(this.booking.hotelsByDay[day.DayNr]), italics: true, bold: true },
                ]
            });
        }

        objects.push(spacer);
        objects.push(hotelData);

        let newObjects = [];
        for (let i in objects) {
            let t = objects[i];
            if (t.text && t.text.length>0 && t.text[0].text=="" && !t.spacer) continue;
            let l = [];
            if (t.rowList) {
              for (let pi in t.rowList) {
                let p = t.rowList[pi];
                let img;
                let rowList = {values: [], image: img, style: p.style}
                for (let ri in p.text) {
                  let r = p.text[ri];
                  if (t.image && pi==0 && ri==0) img = t.image;
                  if (ri == 0 && p.image && p.image.id) img = p.image;
                  if (r.image && r.image.id) img = r.image;
                  if (img) rowList.image = img;
                  rowList.values.push(r);
                }
                l.push(rowList);
              }
            } else {
                for (let ri in t.text) {
                  let r = t.text[ri];
                  let img;
                  if (t.image && ri==0) img = t.image;
                  if (r.image) img = r.image;
                  l.push({values: [r], image: img, style: r.style});
                }
            }
            for (let r of l) {
              let img;
              let imgId;
              if (r.image && r.image.img && r.image.img.id) {
                img = r.image;
                imgId = r.image.img.id;
              }
              newObjects.push({imgId: imgId, image: img, values: r.values, style: r.style, tStyle: t.style})
            }
        }

        let packs = [];
        for (let r of newObjects) {
            packs.push({imgId: r.imgId, image: r.image, list: [r]})
        }
        let limit = 840;
        let k = packs.length;
        for (let i=0; i<k;  i++) {
            let pack = packs[i];
            if (pack.imgId) {
              let textLength = pack.list.reduce((m, r) => {
                let k = r.values.reduce((k, value)=>{
                    if (!value.text) return k;
                    return k + value.text.length;
                }, 0);
                return m + k;
              }, 0);
              if (textLength < limit && i < k-1) {
                let nextPack = packs[i+1];



                if (!nextPack.imgId) {
                    let nextTextLength = nextPack.list.reduce((m, r) => {
                        let k = r.values.reduce((k, value)=>{
                            if (!value.text) return k;
                            return k + value.text.length;
                        }, 0);
                        return m + k;
                      }, 0);
                    if ((textLength < (limit / 2)) || ((textLength + nextTextLength) < limit * 1.5) ) {
                        if (!( (textLength > (limit * 0.7)) && (textLength + nextTextLength) > limit * 1.2) ) {
                            pack.list.push(nextPack.list[0]);
                            packs.splice(i + 1, 1);
                            i = i - 1;
                            k = k - 1;
                        }
                    }

                }
              }
            }
        }

        let objectList = [];
        for (let pack of packs) {
            let r = {image: pack.image, paragraphList: [], style: pack.tStyle}
            for (let p of pack.list) {
                r.paragraphList.push({text: p.values, style: p.style, tStyle: p.tStyle})
            }
            objectList.push(r)
            //objectList.push({image: r.image, paragraphList: [{text: [{text: r.text, style: r.style}]}], style: r.tStyle})
        }


        let paragraphs = [];
        let currentLength = 0;
        let list = [];

        for (let i in objectList) {
            let t = objectList[i];
            let pList = this.getServiceParagraphsList(t);
            paragraphs.push(pList);
        }

        for (let i in paragraphs) {
            let pList = paragraphs[i];
            let row = await this.getDayBodyTableRow(pList, doc);
            tables.push(
                new Table({
                    rows: [row],
                    alignment: AlignmentType.CENTER,
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    margins: {
                        marginUnitType: WidthType.AUTO,
                        top: 100,
                        left: 200,
                    },

                    layout: TableLayoutType.FIXED,
                    columnWidths: _.map(row.options.children, function(r) {
                        return r.options.width.size * 11000 / 100;
                    }),
                })
            );
        }

        return tables;
    }

    async getDayBodyTableRow (p, doc) {
        let row = new TableRow({
                children: [
                    new TableCell({
                        children: [],
                        width: {
                            size: 15,
                            type: WidthType.PERCENTAGE,
                        },
                        borders: {
                            top: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            bottom: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            left: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            right: { style: BorderStyle.SINGLE, size: 3, color: "000000"},
                        }
                    }),
                    new TableCell({
                        children: p.list,
                        width: {
                            size: 50,
                            type: WidthType.PERCENTAGE,
                        },
                        margins: {
                            top: 100,
                            left: 200,
                        },
                        borders: {
                            top: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            bottom: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            left: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            right: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                        }
                    }),
                    new TableCell({
                        children: await this.getImage(doc, p.image),
                        width: {
                            size: 35,
                            type: WidthType.PERCENTAGE,
                        },
                        margins: {
                            top: 100,
                            left: 200,
                        },
                        borders: {
                            top: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            bottom: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            left: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                            right: { style: BorderStyle.NONE, size: 1, color: "FFFFF"},
                        }
                    }),
                ],
        });
        return row;
    }

    async getImageDay (image, dayNr) {
        let file = await this.getBase64Image(image.id, image.url, null, 0.5);
        this.dayImages[dayNr] = file;
    }

    async getImage (doc, image) {
        if (!image || !image.img) return [];
        let file = await this.getBase64Image(image.img.id, image.img.url, null, 0.5);
        if (!file) return [];
        let img = Media.addImage(doc, file, 218, 160);
        return [new Paragraph(img)];
    }

    async getDayImages () {
        let promises = [];
        for (let day of this.booking.BookingDays) {
            if (!day.Image || !day.Image.url) continue;
            promises.push(this.getImageDay(day.Image , day.DayNr));
        }
        await Promise.all(promises);
    }

    ////// Tools /////


    replaceTagP (t) {
        let start = t.indexOf('<p ');
        let end = t.indexOf('>', start);
        let tag = t.substring(start, end+1);
        return t.replace('</p>', '').replace(tag, '');
    }

    getStacks (objects) {
        let res = [];
        for (let o of objects) {
            if (!o.html) {
                res.push(o)
            } else {
                if (!o.text) continue;
                let texts = o.text
                    .replace(/&nbsp;/g, '')
                    .replace(/<p>/g, '<br>')
                    .replace(/<ul>/g, '')
                    .replace(/<\/ul>/g, '')
                    //.replace(/(\r\n|\n|\r)/g,'<br>')
                    .replace(/<li><br><\/li>/g,'')
                    .replace(/<br><\/li>/g,'')
                    .replace(/<\/p>/g, '<br>')
                    .replace(/<br><br>/g, '<br>')
                    .replace(/<\/li><li>/g, '<\/li><br><li>')
                texts = texts.split('<br>');

                let nextTag;
                let imageAdded;
                for (let i in texts) {
                    let t = texts[i];
                    if (!t) continue;
                    this.processingText = t;
                    /*if (nextTag) {
                        t = '<' + nextTag + '>' + t;
                    }
                    console.log(101, t);
                    let lastTag = this.getLastTag(t);
                    if (lastTag) {
                        t += '</' + lastTag + '>';
                        nextTag = lastTag;
                    } else {
                        nextTag;
                    }*/
                    let r = {
                        style: o.style,
                        bold: o.bold,
                        italics: o.italics,
                        border: o.border,
                    }
                    if (o.image && !imageAdded) {
                        r.image = o.image;
                        imageAdded = true;
                    }
                    r.text = this.getStyleText(t);
                    res.push(r);
                }
            }
        }
        let r = [];
        for (let p of res) {
            let bullets = false;
            for (let t of p.text) {
                if (t.bullet) {
                    bullets = true;
                    r.push({
                        style: p.style,
                        bullet: {level: 0},
                        text: [t],
                        image: p.image
                    })
                }
            }
            if (!bullets) {
                r.push(p);
            }
        }
        return r;
    }

    getStyleText (t) {
        let res = t;
        if (res.indexOf('<p ')>-1) {
            res = this.replaceTagP(res);
        }
        res = res.replace(/&amp;/g, '&');
        let styles = [];
        let style = this.processTag(res);
        while (style && style.text) {
            if (style.style) {
                styles.push(style.style);
            }
            style = this.processTag(style.text);
        }
        if (style && style.style) {
            styles.push(style.style);
        }
        return styles;
    }

    processTag (t) {
        let nextTag = this.getTag(t);
        if (nextTag) {
            if (nextTag.start>0) {
                let style = {};
                style.text = t.substring(0, nextTag.start);
                return {style: style, text: t.substring(nextTag.start, t.length)}
            } else {
                let text = t.substring(nextTag.start, nextTag.closeTag);
                return {style: this.getTagStyle(text), text: t.substring(nextTag.closeTag, t.length)};
            }
        } else {
            let style = {};
            style.text = t.substring(0, t.length);
            return {style: style, text: null}
        }
    }

    getTagStyle (text, object) {
        let t = text;
        let res = {}
        if (object) res = object;
        if (t.indexOf('style')>-1 || t.indexOf('class=')>-1) {
            if (t.indexOf('color: rgb')>-1) {
                let colors = t.substring(t.indexOf('rgb(')+4, t.indexOf(')', t.indexOf('rgb(')));
                let c = colors.split(',');
                let color = this.fullColorHex(parseInt(c[0]), parseInt(c[1]), parseInt(c[2]));
                res.color = '#' + color;
            }
            //let tag = t.substring(t.indexOf('<')+1, t.indexOf(' ', t.indexOf('<')));
            let tag = this.getTagName(t, t.indexOf('<'), t.length-1);
            if (t.indexOf('ql-align-justify')>-1) res.justify = true;
            if (tag=='strong') res.bold = true;
            if (tag=='em') res.italics = true;
            if (tag=='li') res.bullet = true;
            if (tag=='u') res.decoration = 'underline';
            let tagEnd = t.indexOf('>', t.indexOf('<')+1);
            let completeTag = t.substring(t.indexOf('<'), tagEnd+1);
            t = t.replace(completeTag, "").replace("</" + tag +">", "");
        }
        if (t.indexOf("<strong>")>-1) {
            t = t.replace("<strong>", "").replace("</strong>", "");
            res.bold = true;
        }
        if (t.indexOf("<em>")>-1) {
            t = t.replace("<em>", "").replace("</em>", "");
            res.italics = true;
        }
        if (t.indexOf("<u>")>-1) {
            t = t.replace("<u>", "").replace("</u>", "");
            res.decoration = 'underline';
        }
        if (t.indexOf("<ul>")>-1) {
            t = t.replace("<ul>", "").replace("</ul>", "");
        }
        if (t.indexOf("<li>")>-1) {
            t = t.replace("<li>", "").replace("</li>", "");
            res.bullet = true;
        }
        if (t.indexOf("<a")>-1) {
            return res;
        }
        let containsTags = (t.indexOf('<')>-1 && t.indexOf('</')>-1);
        if (containsTags) {
            res = this.getTagStyle(t, res);
            return res;
        } else {
            res.text = t;
            return res;
        }
    }

    getAttribute (t, attr) {
        let i = t.indexOf(attr);
        if (i > -1 ) {
            let i1 = t.indexOf('"', i+1);
            let i2 = t.indexOf('"', i1+1);
            return t.substring(i1+1, i2);
        }
    }

    countTagTimes (t, tag) {
        let count = 0;
        let position = 0;
        while ((position = t.indexOf(tag, position)) !== -1) {
            count++;
            position += tag.length;
        }
        return count;
    }

    //<span style="font-size: 14px;"><span class="ql-cursor">﻿﻿</span>El Querandí propone un tour della storia del Tango in cinque blocchi storici: “Origini”, “Immigrati e Arrabal”, “Gardel, la Canción del Tango”, “Salones y Milongas”, “Modernismo”. Il tutto accompagnato da una squisita cena à la carte di tre portate (più di venti opzioni di piatti tra antipasti, primi piatti e dessert) accompagnata da incomparabili vini argentini.</span>
    getTag (t) {
        let tagStart = t.indexOf("<");
        let tagEnd = -1;
        if (tagStart>-1) {
            tagEnd = t.indexOf(">", tagStart);
        }
        if (tagStart>-1 && tagEnd>-1) {
            let tag = this.getTagName(t, tagStart, tagEnd);
            let count = this.countTagTimes(t, '<' + tag);
            //let closeTag = t.indexOf("</" + tag + ">", tagEnd);
            let closeTag = this.findNthIndex(t, "</" + tag + ">", count, tagEnd);
            //console.log(t, tag, closeTag, closeTag2);
            return {tag: t.substring(tagStart+1, tagEnd), start: tagStart, end: tagEnd, closeTag: closeTag + tag.length + 3};
        }
    }

    findNthIndex (str, substr, n, startIndex) {
        let index = -1;
        for (let i = 0; i < n; i++) {
            index = str.indexOf(substr, startIndex);
            if (index === -1) {
                break; // If substring not found, exit loop
            }
            startIndex = index + 1; // Update startIndex for next search
        }
        return index;
    }

    getLastTag (t) {
        let tagStart = t.lastIndexOf("<");
        if (t[tagStart+1]=='/') return;
        let tagEnd = -1;
        if (tagStart>-1) {
            tagEnd = t.indexOf(">", tagStart);
        }
        if (tagStart>-1 && tagEnd>-1) {
            let tag = this.getTagName(t, tagStart, tagEnd);
            let closeTag = t.indexOf("</" + tag + ">", tagEnd);
            if (closeTag==-1) {
                return tag;
            }
        }
    }

    getTagName (text, start, end) {
        let t = text.substring(start, end+1);
        let tagEnd1 = t.indexOf(" ");
        let tagEnd2 = t.indexOf(">");
        let lTags = [];
        if (tagEnd1>-1) lTags.push(tagEnd1);
        if (tagEnd2>-1) lTags.push(tagEnd2);
        let tagEnd = Math.min(...lTags);
        return t.substring(t.indexOf('<')+1, tagEnd);
    }

    rgbToHex  (rgb) {
      let hex = Number(rgb).toString(16);
      if (hex.length < 2) {
           hex = "0" + hex;
      }
      return hex;
    }

    fullColorHex (r,g,b) {
      let red = this.rgbToHex(r);
      let green = this.rgbToHex(g);
      let blue = this.rgbToHex(b);
      return red+green+blue;
    }

    userProfileColumn () {
    }

    async getDaysObjects () {
        let res = [];
        for (let i in this.getSortedDays) {
            let day = this.getSortedDays[i];
            res.push([await this.dayPrintObject(day, i)]);
        }
        return res;
    }

    async dayPrintObject (day, dayNr) {
        let res =  {
          id: 'day' + day.DayNr,
          layout: 'noBorders', // optional
          layout: {
                hLineWidth  (i, node) {
                    return 1;
                },
                vLineWidth  (i, node) {
                    return 0.5;
                },
                hLineColor  (i, node) {
                    return 'black';
                },
                vLineColor  (i, node) {
                    return '#' + this.textColor;
                },
          },

          table: {
            widths: [ '10%', '50%', '40%'],
            body: [
              [
                {
                    border: [false, false, true, false],
                    fillColor: '#F1F1F2',
                    stack: [
                        {
                            text: this.tr('Day'),
                            alignment: 'center',
                            color: '#' + this.textColor,
                            fontSize: 10
                        },
                        {
                            text: this.getDatesText(day),
                            alignment: 'center',
                            bold: true,
                            color: '#' + this.textColor,
                            fontSize: 10
                        }
                    ]
                },
                {
                    border: [false, false, false, false],
                    fillColor: '#F1F1F2',
                    margin: [ 10, 0, 0, 0],
                    stack: [
                        {
                            text: this.getCitiesNames(day),
                            alignment: 'left',
                            color: '#' + this.textColor,
                            bold: true,
                            fontSize: 10
                        },
                        {
                            text: day.Title,
                            alignment: 'left',
                            color: '#' + this.textColor,
                            fontSize: 10
                        }
                    ]
                },
                {
                    border: [false, false, false, false],
                    fillColor: '#F1F1F2',
                    text: ''
                }
              ],
              [
                {
                    border: [false, false, true, false],
                    margin: [ 0, 5, 0, 0],
                    fillColor: '#FFFFFF',
                    text: '',
                },
                {
                    border: [false, false, false, false],
                    margin: [ 10, 5, 0, 0],
                    fillColor: '#FFFFFF',
                    stack: this.getServiceObjects(day)
                },
                {
                    border: [false, false, false, false],
                    image: await this.getBase64Image(day.Image.id, day.Image.url, null, 0.5),
                    width: 180,
                    alignment: 'center',
                    margin: [ 0, 5, 0, 0],
                    fillColor: '#FFFFFF',
                }
              ]

            ]
          }

        }
        return res;

    }

    setSelectedHotel (dayNr, i) {
        this.hotelSelected[dayNr] = i;
        this.hotelSelected = Object.assign({}, this.hotelSelected);
    }

    getServiceObjects  (day) {
        let stacks = [];
        this.processingDayNr = day.DayNr + 1;
        EventBus.$emit('processing-day', this.processingDayNr);
        let imageAdded = false;
        for (let s of day.BookingDayServices) {
            let image;
            if (day.Image && day.Image.id && !imageAdded && s.Description) {
                image = day.Image;
                imageAdded = true;
            }
            if (!image || !image.id) {
                if (s && s.BookingDayServiceImages) {
                  for (let i of s.BookingDayServiceImages) {
                      if (i.Image && i.Image.id) {
                          image = i.Image;
                          imageAdded = true;
                          break;
                      }
                  }
                }
            }
            let sTitle = this.getServiceTitle(s);
            if (sTitle && !s.Optional) {
                stacks.push({text: sTitle, html: true, italics: false, style: 'serviceTitle'});
            }

            if (s.Optional) {
                let sTitleName = this.getServiceTitleName(s);
                stacks.push({text: sTitleName, html: true, italics: false, style: 'serviceTitle'});
                stacks.push({text: this.tr('(Optional Service)'), html: true, italics: true, style: 'serviceTitleOptional'});
            }

            stacks.push({text: s.Description, html: true, style: 'serviceText', image: {img: image}});
            if (!this.booking.typeOfServices[day.DayNr] && s.Service.ServiceType && s.Description && s.Description.length>0) {
                let text = this.tr('Type of service') + ': ' + this.tr(s.Service.ServiceType.Name);
                stacks.push({text: text, html: true, italics: true, bold: true, style: 'serviceText' });
            }
        }
        let res = this.getStacks(stacks);
        return res;
    }

    getServiceTitle (s) {
        if (!s) return;
        if (s.Title) return s.Title;
        if (!s.Service) return;
        let t = _.find(s.Service.ServiceDescriptions, (c) => c.LanguageId==this.booking.LanguageId);
        if (t) return t.Title;
    }

    getServiceTitleName (s) {
        let r = this.getServiceTitle(s);
        if (r) return r;
        if (s.Name) return s.Name;
        if (s.Service && s.Service.Name) return s.Service.Name;
    }

    getHotelObjects  (hotelDescription) {
        let stacks = [];
        stacks.push({text: hotelDescription, html: true, style: 'serviceText'});
        let res = this.getStacks(stacks);
        return res;
    }

    getDayNumber (day) {
        if (day.GroupDays && day.GroupDayTo) {
            let r = parseInt(day.DayNr) + 1;
            return `${this.tr('_From')} ${this.tr('Day')} ${r} ${this.tr('_TO')} ${day.GroupDayTo}`;
        }
        return parseInt(day.DayNr) + 1;
    }

    getCitiesNames (day){
        return day.getCitiesNames(this.booking.hotelsByDay[day.DayNr]);
    }

    getMeals (dayNr) {
        let day = _.find(this.booking.BookingDays, (d) => d.DayNr == dayNr);
        if (day) {
            let meals = [];
            if (day._breakfast) meals.push(this.tr('Breakfast'));
            if (day._lunch) meals.push(this.tr('Lunch'));
            if (day._snack) meals.push(this.tr('Snack'));
            if (day._boxLunch) meals.push(this.tr('Box Lunch'));
            if (day._dinner) meals.push(this.tr('Dinner'));
            if (meals.length==0) return
            let res = meals.join(', ');
            let lastC = res.lastIndexOf(', ');
            if (lastC==-1) return res
            res = res.substring(0, lastC) + ' & ' + res.substring(lastC+2, res.length);
            return res;
        }
    }

    getDatesText (day) {
        if (day.GroupDays && day.GroupDayTo) {
            let r1 = this.getDayNr(day) + ' ' + this.getDayMonth(day);
            let r2 = '';
            let dayTo = _.find(this.booking.BookingDays, (p) => p.DayNr == day.GroupDayTo - 1);
            if (dayTo) {
                r2 = this.getDayNr(dayTo) + ' ' + this.getDayMonth(dayTo);
            }
            return `${this.tr('From')} ${r1} ${this.tr('TO_')} ${r2}`;
        }
        return this.getDayNr(day) + ' ' + this.getDayMonth(day);
    }

    getDayMonth (day) {
        return moment(this.booking.StartDate).add(day.DayNr,'days').locale(this.booking.Language.Code).format("MMM").substring(0,3);
    }

    getDayNr (day) {
        return moment(this.booking.StartDate).add(day.DayNr,'days').format("DD");
    }

    ifHotel (h) {
        return h && h.ifHotel;
    }

    getRoomCategoryName (hotelDay) {
        let hotelList = [];
        let hotels = {};
        for (let room of hotelDay.BookingDayRooms) {
            //let room = hotelDay.BookingDayRooms[i];
            if (!room.Hotel) continue;
            if (!room.Hotel.id) continue;
            if (!room.Hotel.RoomCategories) continue;
            if (!room.roomQuoteSelected && this.booking.anyQuoteSelected) continue;
            if (!hotels[room.Hotel.id]) {
                hotelList.push(room.Hotel.id);
                hotels[room.Hotel.id] = {rooms: [], hotelName: room.Hotel.Name};
                if (room.HotelCategory && room.HotelCategory.Name) {
                    hotels[room.Hotel.id].HotelCategory = room.HotelCategory.Name;
                }
            }
            for (let category of room.Hotel.RoomCategories) {
                if (category.id == room.RoomCategory && hotels[room.Hotel.id].rooms.indexOf(category.Name)==-1) {
                    hotels[room.Hotel.id].rooms.push(category.Name);
                }
            }
        }
        let res = [];
        for (let id of hotelList) {
            let hotel = hotels[id];
            let r = '';
            if (hotel.HotelCategory) {
                r += hotel.HotelCategory + ': ';
            }
            r += hotel.hotelName + ' (' + hotel.rooms.join(', ') + ')';
            res.push(r);

        }
        return res.join(' / ');
    }


    convertImgToBase64URL (url, border, quality, callback){
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function(){
            let canvas = document.createElement('CANVAS');
            let ctx = canvas.getContext('2d');
            let dataURL;
            canvas.height = img.height;
            canvas.width = img.width;
            let x = 0;
            let y = 0;
            if (border) {
                canvas.height += border.width * 2;
                canvas.width += border.width * 2;
                ctx.strokeStyle = border.color;
                ctx.lineWidth = border.width * 2;
                ctx.strokeRect(0, 0, canvas.width, canvas.height);
                x += border.width;
                y += border.width;
            }
            ctx.drawImage(img, x, y);
            //ctx.strokeRect(100, 100, 250, 100);
            dataURL = canvas.toDataURL('image/jpeg', quality);
            callback(dataURL);
            canvas = null;
        };
        img.src = url;
    }

    async getBase64Image (id, url, border, quality) {
        if (!id) return null;
        let self = this;
        try {
            return new Promise((resolve) => {
                let serverurl = api.serverEndpoint;
                $.ajax( {
                    method: 'get',
                    url: serverurl + '/api/image/get_image_file/' + id,
                    crossDomain: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    success: function (result) {
                        self.convertImgToBase64URL(serverurl + result, border, quality, function(base64Img){
                            resolve(base64Img);
                        });
                    },
                    fail: function (error) {
                        reject(error)
                    }
                })
                /*this.convertImgToBase64URL(url, border, quality, function(base64Img){
                    resolve(base64Img);
                });*/
            });
        } catch (e) {
            console.log('image error', e)
            return null;
        }
    }

    getHotelText (descriptions, langId) {
        let t = _.find(descriptions, (c) => c.LanguageId==langId);
        if (t) return t.Description;
        return '';
    }


}

export default Docx;

<template>
    <div>
        <div class="settings-buttons d-flex flex-column" id="settings-buttons">
            <button type="button" class="settings-button p-0 mx-0 btn" @click="setFullScreen" v-if="currentTab=='preview'">
                <span class="py-0"><font-awesome-icon icon="expand"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" v-if="canEdit" @click="save" id="save-button"
                data-toggle="tooltip" data-placement="top" :title="tr('Save')">
                <span class="py-0"><font-awesome-icon icon="save" class="my-0"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.isTemplate && recordStore.id && canCreateBooking"
                @click="createBooking" data-toggle="tooltip" data-placement="top" :title="tr('Create Quote')">
                <font-awesome-icon icon="dollar-sign"/>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id"
                @click="webView" data-toggle="tooltip" data-placement="top" :title="tr('Web Itinerary')">
                <span class="py-0"><font-awesome-icon icon="clipboard-list"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" :disabled="recordStore._modified"
                v-if="recordStore.notTemplate && recordStore.id"
                @click="showVersionComment" data-toggle="tooltip" data-placement="top" 
                :title="recordStore._modified? tr('Disabled while pending changes'): tr('Save Version')">
                <span class="py-0"><font-awesome-icon icon="hdd"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id"
                @click="showBookingVersions" data-toggle="modal" rel="tooltip"
                data-placement="top" :title="tr('Versions')">
                <span class="py-0"><font-awesome-icon icon="history"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id"
                @click="showBookingFormActions" data-toggle="tooltip" data-placement="top" :title="tr('Reservation Form')">
                <span class="py-0"><font-awesome-icon icon="passport"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id"
                @click="showNotes" data-toggle="tooltip" rel="tooltip"
                data-placement="top" :title="tr('Notes')">
                <span class="py-0">
                    <font-awesome-icon icon="comment-dots"/>
                    <span v-if="qNotes" class="badge badge-notes">{{qNotes}}</span>
                </span>

            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id"
                @click="createTemplate" data-toggle="tooltip" data-placement="top" :title="tr('Create Template')">
                <span class="py-0"><font-awesome-icon icon="star"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id && (salesActions || managerActions)"
                @click="shareBooking" data-toggle="tooltip" data-placement="top" :title="tr('Share File')">
                <span class="py-0"><font-awesome-icon icon="users"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.id && currentTab=='preview'"
                :disabled="recordStore._modified || currentTab != 'preview'"
                @click="getDocument" data-toggle="tooltip" data-placement="top" 
                :title="recordStore._modified? tr('Disabled while pending changes'): tr('Download Document')">
                <span class="py-0"><font-awesome-icon icon="file-word" /></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" @click="openVoucher()"
                v-if="recordStore.notTemplate && recordStore.id && showDocumentation"
                rel="tooltip" data-placement="top" title="Vouchers">
                <span class="py-0"><font-awesome-icon icon="ticket-alt"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.notTemplate && recordStore.id && showDocumentation"
                @click="finalView()" data-toggle="tooltip" data-placement="top" :title="tr('_DOCS')">
                <span class="py-0"><font-awesome-icon icon="clipboard"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                v-if="recordStore.id && (salesActions || managerActions)"
                :disabled="recordStore._modified"
                @click="copyFile" data-toggle="tooltip" data-placement="top"
                :title="recordStore._modified? tr('Disabled while pending changes'): tr('Copy')">
                <span class="py-0"><font-awesome-icon icon="copy"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" @click="openInvoice()"
                v-if="recordStore.notTemplate && recordStore.id && recordStore.bookingConfirmed"
                rel="tooltip" data-placement="top" :title="tr('Invoice')">
                <span class="py-0"><font-awesome-icon icon="file-invoice"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" @click="showRecordHistory()"
                v-if="recordStore.notTemplate && recordStore.id && canSeeBookingHistory"
                rel="tooltip" data-placement="top" :title="tr('Booking Changes')">
                <record-history class=""></record-history>
            </button>
            <!--slot name="custom"></slot-->
            <button v-for="c of customButtons" type="button" class="settings-button p-0 mx-0 btn" @click="c.click(c)"
                v-if="c.show()" rel="tooltip" data-placement="top" :title="tr(c.title)">
                <component :is="c.component" v-if="c.component"></component>
                <span class="py-0" v-if="c.icon"><font-awesome-icon :icon="c.icon"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" @click="close"
                data-toggle="tooltip" data-placement="top" :title="tr('Close')">
                <span class="py-0"><font-awesome-icon icon="window-close"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" data-toggle="modal"
                v-if="recordStore.notTemplate"
                rel="tooltip" data-placement="top" :title="tr('Booking Views')" @click="showBookingViews">
                <span class="py-0"><font-awesome-icon icon="globe"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                @click="showPriceBreakdown" v-if="recordStore.PriceBreakdown">
                <span class="py-0"><font-awesome-icon icon="list"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn" :class="hasErrors? 'btn-warning': 'btn-secondary'" data-toggle="modal"
                v-if="recordStore.notTemplate"
                rel="tooltip" data-placement="top" :title="tr('Alerts')" @click="showBookingAlerts">
                <span class="py-0"><font-awesome-icon icon="exclamation"/></span>
            </button>
            <button type="button" class="btn-danger settings-button p-0 mx-0 btn"
                v-if="recordStore.id && canDelete && (recordStore.quoteStatus || recordStore.isTemplate)"
                rel="tooltip" @click="$emit('showDelete')">
                <span class="py-0"><font-awesome-icon icon="trash-alt"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                @click="restoreFromLocalStorage" v-if="(salesActions || managerActions) && (!recordStore.bookingConfirmed || !recordStore.id) ">
                <span class="py-0"><font-awesome-icon icon="file-upload"/></span>
            </button>
            <button type="button" class="settings-button p-0 mx-0 btn"
                @click="downloadFile">
                <span class="py-0">
                <font-awesome-icon icon="file-download"/></span>
            </button>
        </div>
        <!--component :is="c.childComponent" :ref="c.childName" v-if="c.childShow" v-for="c of customButtons"
            :show.sync="c.childShow"
        ></component-->
    </div>
</template>


<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
import recordHistory from '@/components/svg/RecordHistory';
export default {
    name: 'booking-button-actions',
    props: ['qNotes'],
    components: {
        'record-history': recordHistory,
    },
    data () {
        return {
            processingDocx: false,
            view: 'edit',
            toDelete: false,
            customButtons: [],
            canSeeBookingHistory: false,
        }
    },
    mounted () {
        this.canSeeBookingHistory = tools.canAccess(api.currentUser, 'api','GET', 'booking_history', false);
    },
    computed: {
        ...mapState({
            salesActions: state => state.main.salesActions,
            currentTab: state => state.booking.currentTab,
            recordStore: state => state.main.record,
            fullScreen: state => state.main.fullScreen,
            managerActions: state => state.main.managerActions,
            operActions: state => state.main.operActions,
        }),
        canCreateBooking () {
            return this.salesActions || this.managerActions;
        },
        showDocumentation () {
            return this.recordStore? this.recordStore.showDocumentation: false;
        },
        canDelete () {
            return this.recordStore? this.recordStore.canDelete: false;
        },
        canEdit () {
            return this.recordStore? this.recordStore.canEdit: false;
        },
        hasErrors () {
            return false;
            //return this.recordStore? this.recordStore.hasErrors: false;
        },
    },
    methods: {
        webView () {
            let route = this.$router.resolve({name: 'itinerary-view',
                params: {hash: this.recordStore.Hash, id: this.recordStore.id}})
            window.open(route.href, '_blank');
        },
        async createBooking () {
            api.setProcessing(true)
            let model = await api.importMixinModule('booking', 'model');
            let booking = await model.createBookingFromTemplate(_.cloneDeep(this.recordStore));
            this.$store.commit('setModified', false);
            this.$router.push({ name: 'my-booking', params: {newBooking: booking, id: 'new'}});
            api.setProcessing(false)
        },
        async createTemplate () {
            api.setProcessing(true)
            let model = await api.importMixinModule('booking', 'model');
            let booking = await model.createTemplateFromBooking(_.cloneDeep(this.recordStore));
            this.$store.commit('setModified', false);
            this.$router.push({ name: 'my-template', params: {newBooking: booking, id: 'new', template: 'PRIVATE'}});
            api.setProcessing(false)
        },
        showVersionComment () {
            this.$parent.showVersionComment();
        },
        showBookingVersions () {
            this.$parent.showVersionList();
        },
        showNotes () {
            this.$parent.showNotes();
        },
        shareBooking () {
            this.$parent.shareBooking();
        },
        openVoucher () {
            let route = this.$router.resolve({
                name: 'voucher',
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id
                }
            })
            window.open(route.href, '_blank');
        },
        finalView () {
            let routeName = 'booking-final';
            let route = this.$router.resolve({
                name: routeName,
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id
                }
            })
            window.open(route.href, '_blank');
        },
        openInvoice () {
            let route = this.$router.resolve({
                name: 'booking-invoice',
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id,
                }
            })
            window.open(route.href, '_blank');
        },
        showBookingFormActions () {
            this.$parent.showBookingFormActions();
        },
        getDocument () {
            EventBus.$emit('get-document');
            api.setProcessing(true);
            //this.processingDocx = true;
        },
        async copyFile () {
            api.setProcessing(true);
            setTimeout(async () => {
                let booking = await this.recordStore.copyFile();
                this.$store.commit('setModified', false);
                this.$store.commit('setCurrentTab', 'cover');
                let routeName = this.$router.currentRoute.name;
                this.$router.push({ name: routeName, params: {newBooking: booking, id: 'new', template: this.recordStore.TemplateType}});
                api.setProcessing(false);
            }, 100);
        },
        showBookingAlerts () {
            this.$parent.showBookingAlerts();
        },
        showRecordHistory () {
            this.$parent.showRecordHistory();
        },
        save () {
            this.$parent.runSave();
        },
        close () {
            this.$parent.close();
        },
        exitFullScreen () {
            this.$store.commit('setFullScreen', false);
        },
        setFullScreen () {
            this.$store.commit('setFullScreen', true);
            this.view = 'full';
        },
        setView (view) {
            this.view = view;
            this.$store.commit('setFullScreen', false);
            if (view!='edit') this.setIFrame();
        },
        async restoreFromLocalStorage () {
            this.$parent.showBackups();
        },
        async downloadFile () {
            let record = tools.plainObject(this.recordStore);
            tools.cleanRelations(record);
            tools.clean(record);
            let data = JSON.stringify(record);
            let name = `File-${this.recordStore.id? this.recordStore.id: 'new'}.json`;
            frontTools.download(name, encodeURIComponent(data), 'json');
            let _xLen = data.length * 2;
            console.log("size  = " + (_xLen / 1024).toFixed(2) + " KB")
        },
        showBookingViews () {
            this.$parent.showBookingViews();
        },
        showPriceBreakdown () {
            let route = this.$router.resolve({name: 'price-breakdown',
                params: {hash: this.recordStore.Hash, id: this.recordStore.id}})
            window.open(route.href, '_blank');
        }
    },

}
</script>
